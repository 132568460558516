import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueTheMask from 'vue-the-mask';
import Notifications from 'vue-notification'
import CookieLaw from 'vue-cookie-law';

Vue.use(VueTheMask);
Vue.use(Notifications);
Vue.use(CookieLaw);
Vue.config.productionTip = false;

new Vue({
  created () {
    AOS.init()
  },
  router,
  render: (h) => h(App),
}).$mount('#app');