import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/sobre',
    name: 'Sobre',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/sobre.vue'),
  },
  {
    path: '/contato',
    name: 'Contato',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/contato.vue'),
  },
  {
    path: '/politica-privacidade',
    name: 'Política de Privacidade',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/politica-privacidade.vue'),
  }, 
  {
    path: '/solucoes/clinicas-e-consultorios/minha-clinica',
    name: 'Minha Clínica',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/clinicas-e-consultorios/minha-clinica.vue'),
  },
  {
    path: '/solucoes/hospitais/controle-assistencial',
    name: 'Controle Assistencial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/hospitais/controle-assistencial.vue'),
  },
  {
    path: '/solucoes/hospitais/apoio-e-faturamento',
    name: 'Apoio e Faturamento',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/hospitais/apoio-e-faturamento.vue'),
  },
  {
    path: '/solucoes/hospitais/controle-de-suprimentos',
    name: 'Controle de Suprimentos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/hospitais/controle-de-suprimentos.vue'),
  },
  {
    path: '/solucoes/hospitais/controle-administrativo',
    name: 'Hospitais - Controle Administrativo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/hospitais/controle-administrativo.vue'),
  },
      {
    path: '/solucoes/operadoras-de-planos/gestao-operadora',
    name: 'Gestão da Operadora',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/gestao-operadora.vue'),
  },
        {
    path: '/solucoes/operadoras-de-planos/cartao-saude',
    name: 'Cartão de Saúde',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/cartao-saude.vue'),
  },
        {
    path: '/solucoes/operadoras-de-planos/faturamento',
    name: 'Faturamento',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/faturamento.vue'),
  },
        {
    path: '/solucoes/operadoras-de-planos/controle-administrativo',
    name: 'Controle Administrativo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/controle-administrativo.vue'),
  },
    {
    path: '/solucoes/operadoras-de-planos/ans-agencia-nacional-de-saude',
    name: 'ANS - Agência Nacional de Saúde',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/ans-agencia-nacional-de-saude.vue'),
  },
  {
    path: '/solucoes/operadoras-de-planos/controle-de-planos',
    name: 'Controle de Planos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/controle-de-planos.vue'),
  },
  {
    path: '/solucoes/operadoras-de-planos/controle-financeiro',
    name: 'Controle Financeiro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/controle-financeiro.vue'),
  },
  {
    path: '/solucoes/operadoras-de-planos/sistema-de-contabilidade-gerencial',
    name: 'Sistema de Contabilidade Gerencial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/sistema-de-contabilidade-gerencial.vue'),
  },
  {
    path: '/solucoes/operadoras-de-planos/sistema-de-gerenciamento-patrimonial',
    name: 'Sistema de Gerenciamento Patrimonial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/solucoes/operadoras-de-planos/sistema-de-gerenciamento-patrimonial.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
  } else {
      return { x: 0, y: 0 }
  }
}
});

export default router;