<template>
  <div class="header">
    <div id="header" class="header-white">
      <div class="header-content">
        <div class="header-content-logo">
          <a @click="Home()" class="logo"></a>
        </div>
        <div id="header-content-nav" class="header-content-nav">
          <div class="header-content-nav-item item-solucoes">
            <a href="#" id="btn-solucoes" class="btn-ativo drop-down"
              >Soluções</a
            >
            <div class="menu">
              <div class="menu-content">
                <div class="menu-content-img"></div>
                <div class="menu-content-nav">
                  <div class="menu-content-nav-item" id="menu-item-clinicas">
                    <h5><span>SPDATA</span> Minha Clínica</h5>
                    <p class="profissional">Profissional</p>
                    <ul>
                      <li>
                        <a
                          href="https://www-spdata-com-br-1.rds.land/portfolio-spdata-minha-clinica"
                          target="_blank"
                          >Funcionalidades</a
                        >
                      </li>
                      <li>
                        <router-link
                          to="/solucoes/clinicas-e-consultorios/minha-clinica/#minha-clinica-planos"
                          >Planos</router-link
                        >
                      </li>
                      <li>
                        <a
                          href="https://web.spdataminhaclinica.com.br"
                          target="_blank"
                          >Acesso ao sistema</a
                        >
                      </li>
                    </ul>
                    <p class="paciente">Paciente</p>
                    <ul>
                      <li>
                        <a
                          href="https://agendamento.spdataminhaclinica.com.br"
                          target="_blank"
                          >Agendamento Online</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="menu-content-nav-item" id="menu-item-hospitais">
                    <h5><span>SPDATA</span> Gestão Hospitalar</h5>
                    <ul>
                      <li>
                        <a
                          href="http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-assistencial"
                          target="_blank"
                          >Assistencial</a
                        >
                      </li>
                      <li>
                        <a
                          href="http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-administrativo "
                          target="_blank"
                          >Administrativo</a
                        >
                      </li>
                      <li>
                        <a
                          href="http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-apoio "
                          target="_blank"
                          >Apoio</a
                        >
                      </li>
                      <li>
                        <a
                          href="http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-suprimentos"
                          target="_blank"
                          >Suprimentos</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="menu-content-nav-item" id="menu-item-operadoras">
                    <h5><span>SPDATA</span> Gestão de Operadoras</h5>
                    <ul>
                      <li>
                        <a
                          href="https://www-spdata-com-br-1.rds.land/portfolio-spdata-sgo"
                          target="_blank"
                          >Funcionalidades</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-content-nav-item">
            <router-link to="/sobre">Sobre</router-link>
          </div>
          <!--<div class="header-content-nav-item">
            <a href="http://noticias.spdata.com.br/">Notícias</a>
          </div>-->
          <div class="header-content-nav-item">
            <a href="http://vagaspdata.rhgestor.com.br/" target="_blank"
              >Trabalhe conosco</a
            >
          </div>
          <div class="header-content-nav-item">
            <router-link to="/contato">Contato</router-link>
          </div>
        </div>
        <div class="header-content-right">
          <div class="header-content-right-item">
            <a
              href="https://www.linkedin.com/company/spdata/"
              target="_blank"
              class="btn-social linkedin"
            ></a>
          </div>
          <div class="header-content-right-item">
            <a
              href="https://www.facebook.com/SPDATA.Gestaohospitalar"
              target="_blank"
              class="btn-social facebook"
            ></a>
          </div>
          <!--
          <div class="header-content-right-item">
            <a
              href="https://twitter.com/Spdata"
              target="_blank"
              class="btn-social twitter"
            ></a>
          </div>
          -->
          <div class="header-content-right-item" target="_blank">
            <a
              href="https://www.instagram.com/spdata.gestaohospitalar"
              target="_blank"
              class="btn-social instagram"
            ></a>
          </div>
          <div class="header-content-right-item" target="_blank">
            <a
              href="https://www.youtube.com/channel/UCCDXNps8PQePJs5q9ErHoDA"
              target="_blank"
              class="btn-social youtube"
            ></a>
          </div>
          <!-- <div class="header-content-right-item">
            <button id="btn-buscar" class="btn-buscar"></button>
            <div id="buscar" class="buscar">
              <div class="buscar-content">
                <input
                  type="text"
                  placeholder="Digite aqui para pesquisar..."
                />
                <button
                  id="btn-fechar-pesquisa"
                  class="btn-fechar-pesquisa"
                ></button>
              </div>
            </div>
          </div> -->

          <div class="header-content-right-item item-entrar">
            <a href="#" id="btn-entrar" class="btn-entrar drop-down btn-ativo"
              >Entrar</a
            >
            <div id="nav-portal" class="nav-portal">
              <div class="nav-portal-content">
                <ul>
                  <li>
                    <a
                      href="https://web.spdataminhaclinica.com.br"
                      target="_blank"
                      class="btn-minha-clinica"
                      >SPDATA - Minha Clínica</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://cliente.spdata.com.br/"
                      target="_blank"
                      class="btn-portal-cliente"
                      >Portal do Cliente</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://colaborador.spdata.com.br/"
                      target="_blank"
                      class="btn-portal-colaborador"
                      >Portal do Colaborador</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="mobile">
        <div id="nav-icon4" class="btn-menu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav class="menu-mobile">
          <ul class="menu-mobile-pri">
            <li>
              <router-link
                class="btn-close"
                to="/solucoes/clinicas-e-consultorios/minha-clinica"
                >Soluções</router-link
              >
            </li>
            <li>
              <router-link class="btn-close" to="/sobre">Sobre</router-link>
            </li>
            <!--<li><a href="http://noticias.spdata.com.br/">Notícias</a></li>-->
            <li>
              <a href="http://vagaspdata.rhgestor.com.br/" target="_blank"
                >Trabalhe conosco</a
              >
            </li>
            <li>
              <router-link class="btn-close" to="/Contato">Contato</router-link
              >
            </li>
            <li>
              <a href="https://web.spdataminhaclinica.com.br" target="_blank"
                >SPDATA - Minha Clínica</a
              >
            </li>
            <li>
              <a
                href="https://agendamento.spdataminhaclinica.com.br"
                target="_blank"
                >Agendamento Online</a
              >
            </li>
            <li>
              <a
                href="https://cliente.spdata.com.br/"
                target="_blank"
                >Portal do Cliente</a
              >
            </li>
            <li>
              <a
                href="https://colaborador.spdata.com.br/"
                target="_blank"
                >Portal do Colaborador</a
              >
            </li>
          </ul>
          <div class="menu-mobile-social">
            <div class="header-content-right-item">
              <a
                href="https://www.linkedin.com/company/spdata/"
                target="_blank"
                class="btn-social linkedin"
              ></a>
            </div>
            <div class="header-content-right-item">
              <a
                href="https://www.facebook.com/SPDATA.Gestaohospitalar"
                class="btn-social facebook"
                target="_blank"
              ></a>
            </div>
            <div class="header-content-right-item" target="_blank">
              <a
                href="https://www.instagram.com/spdata.gestaohospitalar"
                class="btn-social instagram"
                target="_blank"
              ></a>
            </div>
            <div class="header-content-right-item" target="_blank">
              <a
                href="https://www.youtube.com/channel/UCCDXNps8PQePJs5q9ErHoDA"
                class="btn-social youtube"
                target="_blank"
              ></a>
            </div>
          </div>
        </nav>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    Home() {
      window.location.href = "/";
    },
  },
};
</script>
