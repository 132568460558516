<template>
  <div class="footer">
    <div class="footer-content">
      <div class="row">
        <div
          class="col col-1-4 container-footer-logo"
          data-aos="fade-up"
          data-aos-duration="300"
        >
          <a @click="Home()" class="footer-logo"></a>
        </div>
        <div class="col col-1-4" data-aos="fade-up" data-aos-duration="600">
          <nav class="footer-content-nav">
            <h5>SPDATA</h5>
            <ul>
              <li><a @click="Home()">Home</a></li>
              <li>
                <router-link to="/solucoes/clinicas-e-consultorios/minha-clinica"
                  >Soluções</router-link
                >
              </li>
              <li><router-link to="/sobre">Sobre</router-link></li>
              <li><a href="http://noticias.spdata.com.br/">Notícias</a></li>
              <li><router-link to="/contato">Contato</router-link></li>
            </ul>
          </nav>
        </div>
        <div class="col col-1-4" data-aos="fade-up" data-aos-duration="900">
          <nav class="footer-content-nav">
            <h5>Links Úteis</h5>
            <ul>
              <li>
                <a
                  href="https://cliente.spdata.com.br/"
                  target="_blank"
                  >Portal do Cliente</a
                >
              </li>
              <li>
                <a
                  href="https://colaborador.spdata.com.br/"
                  target="_blank"
                  >Portal do Colaborador</a
                >
              </li>
              <li>
                <a href="http://vagaspdata.rhgestor.com.br/" target="_blank"
                  >Trabalhe Conosco</a
                >
              </li>
              <li><a href="https://spdata.digitalinnovation.one/sign-in" target="_blank">Universidade SPData</a></li>
              <li>
                <a href="https://app.protegon.com.br/#/external_request/d457b7fa" target="_blank">Política de Privacidade</a>
              </li>
                            <li>
                <a href="https://app.protegon.com.br/#/external_incident_complaint/3bd45c78" target="_blank">Canal de Denúncia</a>
              </li>
              <li>
                <a
                  href="https://cdn.spdata.com.br/pdfs/SGHCot.pdf"
                  >Baixe o SGHCot</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <div
          class="col col-1-4 container-newsletter"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="footer-content-nav">
            <h5>Receba nossas novidades!</h5>
            <div class="newsletter">
              <input
                type="email"
                id="news_email"
                name="news_email"
                placeholder="E-mail"
                class="input-white"
                v-model="email"
              />
              <button
                @click="saveNewsLetter"
                value="Assinar"
                class="btn btn-sm"
              >
                Assinar
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr data-aos="fade-up" />
      <div class="row">
        <div class="col col-1-4" data-aos="fade-up" data-aos-duration="300">
          <nav class="footer-content-nav">
            <h5 class="social">Redes Sociais</h5>
            <ul class="footer-redes-sociais">
              <li>
                <a
                  href="https://www.linkedin.com/company/spdata/"
                  target="_blank"
                  class="btn-social linkedin"
                ></a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/SPDATA.Gestaohospitalar"
                  target="_blank"
                  class="btn-social facebook"
                ></a>
              </li>
              <!--
              <li>
                <a
                  href="https://twitter.com/Spdata"
                  target="_blank"
                  class="btn-social twitter"
                ></a>
              </li>
              -->
              <li>
                <a
                  href="https://www.instagram.com/spdata.gestaohospitalar/"
                  target="_blank"
                  class="btn-social instagram"
                ></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCCDXNps8PQePJs5q9ErHoDA"
                  target="_blank"
                  class="btn-social youtube"
                ></a>
              </li>
            </ul>
          </nav>
        </div>
        <div
          class="col col-1-4 container-contato"
          data-aos="fade-up"
          data-aos-duration="600"
        >
          <nav class="footer-content-nav">
            <h5 class="contato">Contato</h5>
            <p>(31) 3399-2500</p>
          </nav>
        </div>
        <div
          class="col col-1-4 container-onde"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <nav class="footer-content-nav">
            <h5 class="onde">Onde estamos</h5>
            <p>Avenida João Cesar de Oliveira, 5757</p>
            <p>Edifício Contemporâneo, Sala 907</p>
            <p>Beatriz, Contagem/MG</p>
          </nav>
        </div>
        <div
          class="col col-1-4 container-time"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <nav class="footer-content-nav">
            <h5 class="time">Horário de Funcionamento</h5>
            <p>Seg - Sex: 7:30 às 12:00 e 13:30 às 17:00</p>
          </nav>
        </div>
      </div>
    </div>
    <div class="footer-direitos-reservados">
      <div class="footer-direitos-reservados-content">
        <p class="module">
          © 2021 SPDATA - Todos os direitos reservados.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import SetupApplicationService from "../services/SetupApplicationService.js";

export default {
  data() {
    return {
      email: null,
      validations: { email: false },
      setupApplicationService: new SetupApplicationService(),
    };
  },
  methods: {
    displaySuccessTooltip(message, type) {
      this.$notify({
        group: 'main',
        text: message,
        type: type
      });
    },

    async saveNewsLetter(e) {
      const payload = {
        email: this.email,
      };

if (this.checkForm()) {
        try {
          await this.setupApplicationService.setNewsLetter(payload);
          this.displaySuccessTooltip('Sua inscrição foi realizada com sucesso!', 'success')
        } catch {
          this.displaySuccessTooltip('Não conseguimos salvar seu pedido de inscrição no momento.', 'error')
        }
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      if (this.validEmail(this.email)) {
        return true;
      }

      this.validations = {};

      this.validations.email = !this.validEmail(this.email);
    },
    Home() {
      window.location.href = "/";
    },
  },
  components: { CookieLaw }
};
</script>
