import axios from 'axios'
import { env } from "../src/config/env";

let axiosInstance = axios

const api = env.apiUrl;

axiosInstance = axios.create({
    baseURL: api,
});

export default axiosInstance;

