import axiosInstance from "../axios"
import axios from 'axios'

class SetupApplicationService {
    
    async setContato(payload) {   
        let res = await axiosInstance.post('/contato', JSON.stringify(payload),{headers:{"Content-Type" : "application/json"}})     
        return res.data
    }

    setAtendimento(payload) {    
        axiosInstance.post('/atendimento', JSON.stringify(payload),{headers:{"Content-Type" : "application/json"}}).then(res => {  
            return res.data
           }).catch(e => {               
               return e;
           });
    } 

    async setNewsLetter(payload) {    
        let res = await axiosInstance.post('/newsletter', JSON.stringify(payload),{headers:{"Content-Type" : "application/json"}}) 
        return res.data
    } 

    async realizarOrcamentoMinhaClinica(payload) {
        await axios.post('https://api.spdataminhaclinica.com.br/landing-page-email', JSON.stringify(payload),
            {
                headers: {
                    "Content-Type" : "application/json",
                    "no-tenant-id": "true"
                }
            }) 
    }
}

export default SetupApplicationService;
