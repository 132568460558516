var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-white",attrs:{"id":"header"}},[_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"header-content-logo"},[_c('a',{staticClass:"logo",on:{"click":function($event){return _vm.Home()}}})]),_c('div',{staticClass:"header-content-nav",attrs:{"id":"header-content-nav"}},[_c('div',{staticClass:"header-content-nav-item item-solucoes"},[_c('a',{staticClass:"btn-ativo drop-down",attrs:{"href":"#","id":"btn-solucoes"}},[_vm._v("Soluções")]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu-content"},[_c('div',{staticClass:"menu-content-img"}),_c('div',{staticClass:"menu-content-nav"},[_c('div',{staticClass:"menu-content-nav-item",attrs:{"id":"menu-item-clinicas"}},[_vm._m(0),_c('p',{staticClass:"profissional"},[_vm._v("Profissional")]),_c('ul',[_vm._m(1),_c('li',[_c('router-link',{attrs:{"to":"/solucoes/clinicas-e-consultorios/minha-clinica/#minha-clinica-planos"}},[_vm._v("Planos")])],1),_vm._m(2)]),_c('p',{staticClass:"paciente"},[_vm._v("Paciente")]),_vm._m(3)]),_vm._m(4),_vm._m(5)])])])]),_c('div',{staticClass:"header-content-nav-item"},[_c('router-link',{attrs:{"to":"/sobre"}},[_vm._v("Sobre")])],1),_vm._m(6),_c('div',{staticClass:"header-content-nav-item"},[_c('router-link',{attrs:{"to":"/contato"}},[_vm._v("Contato")])],1)]),_vm._m(7)]),_c('section',{attrs:{"id":"mobile"}},[_vm._m(8),_c('nav',{staticClass:"menu-mobile"},[_c('ul',{staticClass:"menu-mobile-pri"},[_c('li',[_c('router-link',{staticClass:"btn-close",attrs:{"to":"/solucoes/clinicas-e-consultorios/minha-clinica"}},[_vm._v("Soluções")])],1),_c('li',[_c('router-link',{staticClass:"btn-close",attrs:{"to":"/sobre"}},[_vm._v("Sobre")])],1),_vm._m(9),_c('li',[_c('router-link',{staticClass:"btn-close",attrs:{"to":"/Contato"}},[_vm._v("Contato")])],1),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)]),_vm._m(14)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('span',[_vm._v("SPDATA")]),_vm._v(" Minha Clínica")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www-spdata-com-br-1.rds.land/portfolio-spdata-minha-clinica","target":"_blank"}},[_vm._v("Funcionalidades")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://web.spdataminhaclinica.com.br","target":"_blank"}},[_vm._v("Acesso ao sistema")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://agendamento.spdataminhaclinica.com.br","target":"_blank"}},[_vm._v("Agendamento Online")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-content-nav-item",attrs:{"id":"menu-item-hospitais"}},[_c('h5',[_c('span',[_vm._v("SPDATA")]),_vm._v(" Gestão Hospitalar")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-assistencial","target":"_blank"}},[_vm._v("Assistencial")])]),_c('li',[_c('a',{attrs:{"href":"http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-administrativo ","target":"_blank"}},[_vm._v("Administrativo")])]),_c('li',[_c('a',{attrs:{"href":"http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-apoio ","target":"_blank"}},[_vm._v("Apoio")])]),_c('li',[_c('a',{attrs:{"href":"http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-suprimentos","target":"_blank"}},[_vm._v("Suprimentos")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-content-nav-item",attrs:{"id":"menu-item-operadoras"}},[_c('h5',[_c('span',[_vm._v("SPDATA")]),_vm._v(" Gestão de Operadoras")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www-spdata-com-br-1.rds.land/portfolio-spdata-sgo","target":"_blank"}},[_vm._v("Funcionalidades")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-content-nav-item"},[_c('a',{attrs:{"href":"http://vagaspdata.rhgestor.com.br/","target":"_blank"}},[_vm._v("Trabalhe conosco")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-content-right"},[_c('div',{staticClass:"header-content-right-item"},[_c('a',{staticClass:"btn-social linkedin",attrs:{"href":"https://www.linkedin.com/company/spdata/","target":"_blank"}})]),_c('div',{staticClass:"header-content-right-item"},[_c('a',{staticClass:"btn-social facebook",attrs:{"href":"https://www.facebook.com/SPDATA.Gestaohospitalar","target":"_blank"}})]),_c('div',{staticClass:"header-content-right-item",attrs:{"target":"_blank"}},[_c('a',{staticClass:"btn-social instagram",attrs:{"href":"https://www.instagram.com/spdata.gestaohospitalar","target":"_blank"}})]),_c('div',{staticClass:"header-content-right-item",attrs:{"target":"_blank"}},[_c('a',{staticClass:"btn-social youtube",attrs:{"href":"https://www.youtube.com/channel/UCCDXNps8PQePJs5q9ErHoDA","target":"_blank"}})]),_c('div',{staticClass:"header-content-right-item item-entrar"},[_c('a',{staticClass:"btn-entrar drop-down btn-ativo",attrs:{"href":"#","id":"btn-entrar"}},[_vm._v("Entrar")]),_c('div',{staticClass:"nav-portal",attrs:{"id":"nav-portal"}},[_c('div',{staticClass:"nav-portal-content"},[_c('ul',[_c('li',[_c('a',{staticClass:"btn-minha-clinica",attrs:{"href":"https://web.spdataminhaclinica.com.br","target":"_blank"}},[_vm._v("SPDATA - Minha Clínica")])]),_c('li',[_c('a',{staticClass:"btn-portal-cliente",attrs:{"href":"https://cliente.spdata.com.br/","target":"_blank"}},[_vm._v("Portal do Cliente")])]),_c('li',[_c('a',{staticClass:"btn-portal-colaborador",attrs:{"href":"https://colaborador.spdata.com.br/","target":"_blank"}},[_vm._v("Portal do Colaborador")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-menu",attrs:{"id":"nav-icon4"}},[_c('span'),_c('span'),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://vagaspdata.rhgestor.com.br/","target":"_blank"}},[_vm._v("Trabalhe conosco")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://web.spdataminhaclinica.com.br","target":"_blank"}},[_vm._v("SPDATA - Minha Clínica")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://agendamento.spdataminhaclinica.com.br","target":"_blank"}},[_vm._v("Agendamento Online")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://cliente.spdata.com.br/","target":"_blank"}},[_vm._v("Portal do Cliente")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://colaborador.spdata.com.br/","target":"_blank"}},[_vm._v("Portal do Colaborador")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-mobile-social"},[_c('div',{staticClass:"header-content-right-item"},[_c('a',{staticClass:"btn-social linkedin",attrs:{"href":"https://www.linkedin.com/company/spdata/","target":"_blank"}})]),_c('div',{staticClass:"header-content-right-item"},[_c('a',{staticClass:"btn-social facebook",attrs:{"href":"https://www.facebook.com/SPDATA.Gestaohospitalar","target":"_blank"}})]),_c('div',{staticClass:"header-content-right-item",attrs:{"target":"_blank"}},[_c('a',{staticClass:"btn-social instagram",attrs:{"href":"https://www.instagram.com/spdata.gestaohospitalar","target":"_blank"}})]),_c('div',{staticClass:"header-content-right-item",attrs:{"target":"_blank"}},[_c('a',{staticClass:"btn-social youtube",attrs:{"href":"https://www.youtube.com/channel/UCCDXNps8PQePJs5q9ErHoDA","target":"_blank"}})])])
}]

export { render, staticRenderFns }